import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import htmlDecode from "html-entities-decoder";
import { GatsbyImage } from "gatsby-plugin-image"

import Introduction from "../components/Global/Introduction"
import Faqs from "../components/Global/Faqs"

import "../components/Services/ServiceTemplate.css"


function ServiceTemplate(props) {
    let data = props.data.wpgraphql.service;
    let services = props.data.wpgraphql.services.edges;
    console.log(props)

    function preloadImage(img) {
        const src = img.getAttribute("data-src");
        if (!src) {
            return;
        }
        img.src = src;
    }

    const imgOptions = {
        threshold: 0,
        rootMargin: "0px 0px 300px 0px"
    };

    useEffect(() => {
        const observer = new PerformanceObserver((list) => {
            let perfEntries = list.getEntries();
            let lastEntry = perfEntries[perfEntries.length - 1];
            console.log(lastEntry.element)
            // Process the latest candidate for largest contentful paint
        });
        observer.observe({ entryTypes: ['largest-contentful-paint'] });


        let imgObserver = new IntersectionObserver((entries, imgObserver) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                } else {
                    preloadImage(entry.target);
                    imgObserver.unobserve(entry.target);
                }
            })
        }, imgOptions)

        let images = document.querySelectorAll("[data-src]");
        images.forEach(image => {
            imgObserver.observe(image)
        })

    }, [])

    function addLazyLoadHandler(content) {
        let lazyLoadedContent;
        lazyLoadedContent = content.replace(/src="/g, 'data-src="')
        lazyLoadedContent = lazyLoadedContent.replace(/srcset="/g, 'data-srcset="')
        return lazyLoadedContent;
    }

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }

        console.log(payload)
        return payload
    }

    return (
        <Layout 
            header={props.data.wpgraphql.menu} 
            footer={props.data.wpgraphql.footer} 
            metaData={constructMetaData(data, props.pageContext.currentPage)}  
            services={props.data.wpgraphql.headerServices} 
            productCategories={props.data.wpgraphql.productCategories} 
        >

            <section>
                <Introduction backImg={data.servicesAcf.backgroundImage} title={htmlDecode(data.title)} />
            </section>
            <section className="container m-auto px-8 md:px-4 my-16 md:my-24">
                <div className="flex flex-wrap lg:flex-no-wrap">
                    <div className="w-full lg:w-2/3 xl:w-4/6 pr-0 lg:pr-4">
                        <GatsbyImage
                            style={{
                                // You can set a maximum height for the image, if you wish.
                                maxHeight: 420,
                            }}
                            // You can optionally force an aspect ratio for the generated image
                            // aspectRatio={3 / 1}
                            // This is a presentational image, so the alt should be an empty string
                            alt="alt"
                            // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash

                            loading={"eager"}

                            image={data.servicesAcf.featuredImage.imageFile.childImageSharp.gatsbyImageData}

                            formats={["auto", "webp", "avif"]}
                        />
                        <div className="service-wrapper w-full mt-4" dangerouslySetInnerHTML={{ __html: data.content }}></div>

                        <section>
                            {(data.faqsAcf.faqs && data.faqsAcf.faqs.faqs && data.faqsAcf.faqs.faqs.length > 0) && (
                                <Faqs
                                    data={data.faqsAcf.faqs}
                                />
                            )}
                        </section>

                    </div>
                    <div className="w-full lg:w-1/3 xl:w-2/6 ss-r-tab mt-16 lg:mt-0 pl-0 lg:pl-8">
                        <div>
                            <div className="ss-side-title">
                                Υπηρεσίες
                            </div>
                            <ul className="ss-nav-ul">
                                {services.map((sc, i) => (
                                    <li key={`nav-sc-${i}`}>
                                        <Link to={`${sc.node.uri.replace("service", "ypiresies")}`} className={props.pageContext.currentPage === sc.node.uri.replace("service", "ypiresies") ? "ss-current-page" : ""}>
                                            {htmlDecode(sc.node.title)}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ServiceTemplate

export const pageQuery = graphql`query GET_SERVICE($id: ID!) {
  wpgraphql {
    menu: template(id:"cG9zdDozMTIz", idType:ID){
        menuAcf{
          menu{
            menuGroup{
              url
              label
              isnested
              childnodes{
                url
                label
              }
            }
          }
        }  
    }

    service(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      breadcrumbAcf {
        breadcrumbName
      }
      title
      content
      uri
      faqsAcf {
        faqs{
            title
            content
            faqs {
                question
                answer
            }
        }
      }
      servicesAcf {
        backgroundImage {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
        featuredImage {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 770
                quality: 100
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    services(first: 20, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
      edges {
        node {
          uri
          title
        }
      }
    }

    headerServices: services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
        ...ServicesSectionFragment
    }

    productCategories{
        edges{
            node{
                name
                slug
                categoryPageH1{
                    categoryImage{
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                              gatsbyImageData(quality: 100, layout: CONSTRAINED, width:400, placeholder: BLURRED)
                            }
                        }
                    }
                }
            }
        }
    }

    footer: template(id: "cG9zdDoyMjE5") {
      id
      title
      footerInformationTemplate {
        footerContent
      address {
        addressGoogleLink
        addressLabel
      }
      hoursLabel
      hoursTitle
      informationTitle
      supportEmail
      telephoneNumber
      servicesTitle
      generalPagesTitle
    }
    }
  }
}
`